<template>
  <v-container
    fluid
    class="fill-height pa-0"
  >
    <v-container
      fluid
      class="white pa-10"
    >
      <h2 class="text-h3 text-center font-weight-bold mb-10">
        {{ $t('preanalise.titulo') }}
      </h2>
    </v-container>
    <v-container
      fluid
      class="pa-10"
    >
      <v-container>
        <v-card
          class="pa-10"
          :elevation="6"
          rounded="xl"
        >
          <v-card-title>
            <v-spacer />
            <h2 class="text-h4 font-weight-bold text-center">
              {{ $t('preanalise.texto') }}
            </h2>
            <v-spacer />
          </v-card-title>
          <v-card-text>
            <PageBuscaResultadoTabelaApi
              url-api="portal/preanalises"
              tipo-api="pública"
            />
          </v-card-text>
        </v-card>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import PageBuscaResultadoTabelaApi from '../widget/PortalBuscaPreanalises.vue';

export default {
  name: 'Preanalise',
  components: {
    PageBuscaResultadoTabelaApi,
  },
  data: () => ({
  }),

};
</script>
