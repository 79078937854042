var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',{attrs:{"align":"center","justify":"space-around"}},[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12","sm":"6","md":"4","lg":"2"}},[_c('div',[_c('v-text-field',{attrs:{"label":_vm.$t('preanalise.Protocolo'),"hide-details":"auto"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.buscandoApi(_vm.consulta)}},model:{value:(_vm.consulta),callback:function ($$v) {_vm.consulta=$$v},expression:"consulta"}})],1)]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"py-4 mx-1",attrs:{"color":"primary","dark":"","depressed":""},on:{"click":function($event){return _vm.buscandoApi(_vm.consulta)}}},[_vm._v(" "+_vm._s(_vm.$t('button.search'))+" ")]),_c('v-btn',{staticClass:"py-4 mx-1",attrs:{"color":"warning","dark":"","depressed":""},on:{"click":function($event){return _vm.limparFiltros()}}},[_vm._v(" "+_vm._s(_vm.$t('button.clear'))+" ")])],1)],1)],1)],1),_c('v-divider'),_c('v-card',[_c('v-card-text',[(_vm.resultado2018.length > 0)?[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers2018,"items":_vm.resultado2018,"items-per-page":5},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status.label)+" ")]}},{key:"item.tipo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.tipo.nome)+" ")]}},{key:"item.resultado",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.resultado === 1 ? 'Inexigível' : 'Exigível')+" ")]}},{key:"item.KML",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":(_vm.apiSysaga2 + "/kmlpreanalisepos2018publico/" + (item.id))}},[_vm._v(" KML ")])]}},{key:"item.PDF",fn:function(ref){
var item = ref.item;
return [(item.abrirProcesso === 'nao')?[_c('a',{attrs:{"href":(_vm.apiSysaga2 + "/pdfpreanalisepos2018publico/" + (item.id))}},[_vm._v(" PDF ")])]:_vm._e()]}}],null,false,1598056551)})]:_vm._e()],2),_c('v-card-text',[(_vm.resultado2015.length > 0)?[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers2015,"items":_vm.resultado2015,"items-per-page":5},scopedSlots:_vm._u([{key:"item.resultado",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.resultado === 1 ? 'Inexigível' : 'Exigível')+" ")]}},{key:"item.KML",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":(_vm.apiSysaga2 + "/kmlpreanalisepre2018publico/" + (item.processoID))}},[_vm._v(" KML ")])]}},{key:"item.PDF",fn:function(ref){
var item = ref.item;
return [(item.arq && item.resultado)?[_c('a',{attrs:{"target":"_blank","href":("http://servicos.decea.gov.br/aga/download/?file=" + (item.arq) + "&tp=certificado&protocolo=" + (item.protoloco))}},[_vm._v(" PDF ")])]:_vm._e()]}}],null,false,1602353454)})]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }