<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-row
          align="center"
          justify="space-around"
        >
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="2"
            class="my-0 py-0"
          >
            <div>
              <v-text-field
                v-model="consulta"
                :label="$t('preanalise.Protocolo')"
                hide-details="auto"
                @keyup.enter="buscandoApi(consulta)"
              />
            </div>
          </v-col>
          <v-col
            cols="auto"
          >
            <!--69B02693 535C200CF4 BDFACB38CC C1CD6E0C39 %CD6%-->
            <v-btn
              class="py-4 mx-1"
              color="primary"
              dark
              depressed
              @click="buscandoApi(consulta)"
            >
              {{ $t('button.search') }}
            </v-btn>
            <v-btn
              class="py-4 mx-1"
              color="warning"
              dark
              depressed
              @click="limparFiltros()"
            >
              {{ $t('button.clear') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-divider />
    <v-card>
      <v-card-text>
        <template v-if="resultado2018.length > 0">
          <!--resultado2018 =   {{ resultado2018 }}-->
          <v-data-table
            :headers="headers2018"
            :items="resultado2018"
            :items-per-page="5"
            class="elevation-1"
          >
            <template v-slot:item.status="{item}">
              {{ item.status.label }}
            </template>
            <template v-slot:item.tipo="{item}">
              {{ item.tipo.nome }}
            </template>
            <template v-slot:item.resultado="{item}">
              {{ item.resultado === 1 ? 'Inexigível' : 'Exigível' }}
            </template>
            <template v-slot:item.KML="{item}">
              <a :href="`${apiSysaga2}/kmlpreanalisepos2018publico/${item.id}`">
                KML
              </a>
            </template>
            <template v-slot:item.PDF="{item}">
              <template v-if="item.abrirProcesso === 'nao'">
                <a :href="`${apiSysaga2}/pdfpreanalisepos2018publico/${item.id}`">
                  PDF
                </a>
              </template>
            </template>
          </v-data-table>
        </template>
      </v-card-text>
      <v-card-text>
        <template v-if="resultado2015.length > 0">
          <!--resultado2015 =  {{ resultado2015 }}-->
          <v-data-table
            :headers="headers2015"
            :items="resultado2015"
            :items-per-page="5"
            class="elevation-1"
          >
            <template v-slot:item.resultado="{item}">
              {{ item.resultado === 1 ? 'Inexigível' : 'Exigível' }}
            </template>
            <template v-slot:item.KML="{item}">
              <a :href="`${apiSysaga2}/kmlpreanalisepre2018publico/${item.processoID}`">
                KML
              </a>
            </template>
            <template v-slot:item.PDF="{item}">
              <template v-if="item.arq && item.resultado">
                <a
                  target="_blank"
                  :href="`http://servicos.decea.gov.br/aga/download/?file=${item.arq}&tp=certificado&protocolo=${item.protoloco}`"
                >
                  PDF
                </a>
              </template>
            </template>
          </v-data-table>
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'PortalBuscaPreanalises',
  props: {
    tipoApi: {
      type: String,
      default: 'pública',
    },
    urlApi: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    headers2018: [
      {
        text: 'Regional',
        align: 'start',
        sortable: false,
        value: 'nomeregional',
      },
      { text: 'Protocolo', value: 'protocolo' },
      { text: 'Tipo', value: 'tipo' },
      { text: 'Solicitação', value: 'criacao' },
      { text: 'Parecer', value: 'status' },
      { text: 'KML', value: 'KML' },
      { text: 'PDF', value: 'PDF' },
    ],
    headers2015: [
      {
        text: 'Regional',
        align: 'start',
        sortable: false,
        value: 'regional',
      },
      { text: 'Protocolo', value: 'protocolo' },
      { text: 'Tipo', value: 'tipo' },
      { text: 'Solicitação', value: 'criacao' },
      { text: 'Parecer', value: 'resultado' },
      { text: 'KML', value: 'KML' },
      { text: 'PDF', value: 'PDF' },
    ],
    resultado2018: [],
    resultado2015: [],
    loading: false,
    consulta: '',
  }),
  computed: {
    apiSysaga2() {
      return process.env.VUE_APP_URL_SysAGA2;
    },
  },
  methods: {
    limparFiltros() {
      this.consulta = '';
    },
    async buscandoApi(consulta) {
      console.log(consulta);
      this.loading = true;
      const apiurl = `${process.env.VUE_APP_URL_API}/${this.urlApi}`;
      console.log(apiurl);
      await this.$axios.post(`${apiurl}`, {
        consulta,
      })
        .then((response) => {
          this.resultado2018 = response.data.resultado;
          this.resultado2015 = response.data.resultado2015;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
